<template>
    <section class="my-4">
        <p class="f-16 text-general f-600">
            Imagenes de acceso
        </p>
        <p class="py-2 text-general">Acceso de {{ $config.cliente }} y {{ $config.vendedor }}</p>
        <div class="row mx-0">
            <div class="col-12 d-flex px-0">
                <div class="mt-2" style="display:inline-flex;">
                    <div class="col-auto px-0 mr-3">
                        <p class="text-general f-15 mb-2">Registrarme</p>
                        <div class="space-img cr-pointer">
                            <img :src="!_.isNull(data.soy_nuevo) ? data.soy_nuevo.imagen : '/img/no-imagen/añadir_imagen.svg'" class="obj-cover" />
                            <el-upload
                            ref="upload1"
                            action="#"
                            accept="image/png"
                            :on-change="changeFile"
                            :auto-upload="false"
                            :multiple="false"
                            :limit="1"
                            :show-file-list="false"
                            >
                                <div class="br-50 btns-cropper mx-1" @click="tipoBoton = 41">
                                    <i class="icon-pencil-outline f-17" />
                                </div>
                            </el-upload>
                        </div>
                        <p class="text-left pl-2 text-grey f-15 pt-2">
                            Formato necesario: PNG
                        </p>
                    </div>
                    <div class="col-auto px-0 mr-3">
                        <p class="text-general f-15 mb-2">Estoy registrado</p>
                        <div class="space-img cr-pointer">
                            <img :src="!_.isNull(data.tengo_cuenta) ? data.tengo_cuenta.imagen : '/img/no-imagen/añadir_imagen.svg'" class="obj-cover" />
                            <el-upload
                            ref="upload2"
                            action="#"
                            accept="image/png"
                            :on-change="changeFile"
                            :on-remove="removeFile"
                            :auto-upload="false"
                            :multiple="false"
                            :limit="1"
                            :show-file-list="false"
                            >
                                <div class="br-50 btns-cropper mx-1" @click="tipoBoton = 42">
                                    <i class="icon-pencil-outline f-17" />
                                </div>
                            </el-upload>
                        </div>
                    </div>
                    <div class="col-auto px-0">
                        <p class="text-general f-15 mb-2">Ingresar como invitado</p>
                        <div class="space-img cr-pointer">
                            <img :src="!_.isNull(data.invitado) ? data.invitado.imagen : '/img/no-imagen/añadir_imagen.svg'" class="obj-cover" />
                            <el-upload
                            ref="upload3"
                            action="#"
                            accept="image/png"
                            :on-change="changeFile"
                            :on-remove="removeFile"
                            :auto-upload="false"
                            :multiple="false"
                            :limit="1"
                            :show-file-list="false"
                            >
                                <div class="br-50 btns-cropper mx-1" @click="tipoBoton = 43">
                                    <i class="icon-pencil-outline f-17" />
                                </div>
                            </el-upload>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Service from "~/services/configurar/admin/personalizacion"
export default {
    props:{
        data:{
            type: Object,
            default:()=>{}
        },
    },
    data(){
        return {
            tipoBoton: null,
            file: null
        }
    },
    methods: {
        async changeFile(file){
            try {
                const isPng = file.raw.type === 'image/png';

                if (!isPng){
                    this.notificacion('Error','Solo se aceptan imagenes con extencion .PNG','warning')
                    this.file = null
                    this.clearFiles()
                    return
                }

                this.file = file.raw;
                let img = this.tipoBoton == 41 ? this.data.soy_nuevo : this.tipoBoton == 42 ? this.data.tengo_cuenta : this.tipoBoton == 43 ? this.data.invitado : null;
                if(this.file != null){

                    let model = {
                        idImage: img ? img.id : '',
                        tipo: this.tipoBoton,
                        file: this.file,
                    };

                    model = this.crear_formData(model);
                    const {data} = await Service.postImagenIngreso(model);
                    this.notificacion('Mensaje', data.mensaje, data.tipo);
                    this.file = null
                    this.clearFiles()
                    if(data.exito){
                        this.$emit('update');
                    }
                }
            } catch(e){
                this.error_catch(e)
            }
        },
        removeFile(){
            this.file = null;
        },
        clearFiles(){
            this.$refs.upload1.clearFiles()
            this.$refs.upload2.clearFiles()
            this.$refs.upload3.clearFiles()
        }
    }
}
</script>
<style lang="scss" scoped>
.space-img{
    width: 235px;
    height: 120px;
    position: relative;
    border: 1px solid #DBDBDB;
    .btns-cropper{
        color: #fff;
        cursor: pointer;
        position: absolute;
        width: 35px;
        left: 41%;
        bottom: 15px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #171817;
        box-shadow: 0px 3px 6px #00000029;
    }
    img{
        width: 100%;
        height: 100%;
    }
}
</style>
